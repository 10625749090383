import React from 'react';
// import { Script } from 'gatsby'

// styles
import "./newsletter.scss";

export default function Newsletter(props) {
  const { copy } = props;

  return (
    <div id="mc_embed_signup" className={`newsletter align-center ${copy ? 'grid grid--2' : null}`}>
      {copy ? <p className={`tiny`}>{copy}</p> : null}

      <form action={`https://templehillent.us6.list-manage.com/subscribe/post?u=4606f92cf482036c7e407f137&amp;id=b525de3075&amp;f_id=0048e7e5f0`} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" className={`newsletter__form flex align-center`}>
        <input className={`input tiny bold uppercase`} name="EMAIL" type="email" id="mce-EMAIL" placeholder="email" required />
        <input className={`button tiny bold `} type="submit" value="Submit" />
         {/* <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display: none;"></div>
            <div class="response" id="mce-success-response" style="display: none;"></div>
        </div> */}
      </form>

      {/* <Script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" /> */}
    </div>
  )
}
